.app {
    button,
    .button,
    .button-goto-screen {
        color           : black;
        font-weight     : bold;
        background-color: white;
        border          : 2px solid black;
        border-radius   : 10px;
        padding         : 10px;
        cursor          : pointer;

        &:hover {
            color           : white;
            background-color: black;
        }

        &:disabled {
            color           : black;
            background-color: gray;
            cursor          : not-allowed;
        }
    }

    .nav-buttons {
        display: block;
        width: fit-content;
        margin : 30px auto 10px auto;

        .button-goto-screen {
            display: inline-block;
            width: 130px;
            margin: 0px 30px;
        }
    }
}