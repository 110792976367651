.custom-text-input,
.show-secret {
    textarea {
        height: 150px;
    }

    button {
        display: block;
        width  : 200px;
        margin : 20px auto;
    }
}
